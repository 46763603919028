import * as React from "react"
import { Link } from "gatsby"

import styles from "./button-link.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const ButtonLink = ({ to, color, download, children }) =>
  to.startsWith("http://") || to.startsWith("https://") || download ? (
    <a
      href={to}
      className={cx("button-link", {
        "button-link--blue": color === "blue",
        "button-link--green": color === "green",
      })}
      target="_blank"
      rel="noopener noreferrer"
      download={download}
    >
      {children}
    </a>
  ) : (
    <Link
      to={to}
      download={download}
      className={cx("button-link", {
        "button-link--blue": color === "blue",
        "button-link--green": color === "green",
      })}
    >
      {children}
    </Link>
  )
