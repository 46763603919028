import * as React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import { FaSteamSymbol, FaItchIo } from "react-icons/fa"
import { FiDownload } from "react-icons/fi"
import { Video } from "../components/video"
import { Image } from "../components/image"
import { ButtonLink } from "../components/button-link"
import { Center } from "../components/center"
import { ImageGrid } from "../components/image-grid"
import { SEO } from "../components/seo"

import "typeface-roboto"
import "typeface-oswald"
import "../styles/document.scss"

const shortcodes = {
  Link,
  ButtonLink,
  Center,
  Video,
  Image,
  ImageGrid,
  IconSteam: FaSteamSymbol,
  IconItch: FaItchIo,
  IconDownload: FiDownload,
} // Provide common components here

const PageTemplate = ({ data: { mdx } }) => {
  const { frontmatter, body } = mdx
  const lang = frontmatter.langKey === "jp" ? "ja" : "en"

  return (
    <>
      <SEO />
      <Helmet htmlAttributes={{ lang }} title={frontmatter.title} />

      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $langKey: String!) {
    mdx(frontmatter: { slug: { eq: $slug }, langKey: { eq: $langKey } }) {
      body
      frontmatter {
        slug
        title
        langKey
      }
    }
  }
`

export default PageTemplate
