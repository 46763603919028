import * as React from "react"

import styles from "./video.module.scss"
import classnames from "classnames/bind"
const cx = classnames.bind(styles)

export const Video = ({ vimeoId, youtubeId }) => {
  if (youtubeId) {
    return (
      <span className={cx("video")}>
        <iframe
          title="Terranova Trailer"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </span>
    )
  }
  if (vimeoId) {
    return (
      <span className={cx("video")}>
        <iframe
          title="Terranova Trailer"
          src={`https://player.vimeo.com/video/${vimeoId}`}
          width="640"
          height="486"
          frameBorder="0"
          allow="fullscreen"
        ></iframe>
      </span>
    )
  }
  return null
}
