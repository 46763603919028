import * as React from "react"
import { Helmet } from "react-helmet"
import articleImage from "../markdown-pages/press/terranova-logotagline-screenshot-article.png"

export const SEO = () => (
  <Helmet>
    <meta
      name="description"
      content="A nostalgic story-based game about fangirls on the early 00's internet. Free demo available on Steam. Available for purchase on on Steam and itch.io."
    />
    <meta name="image" content={articleImage} />
    <meta
      name="keywords"
      content="terranova, game, nostalgia, 2000s, illuminesce, mabbees, livejournal, terranovagame, bl, yaoi, queer, 2000s, limejournal, windows xp, solitaire, studio terranova"
    />
    <meta property="og:title" content="Terranova - Home" />
    <meta
      property="og:description"
      content="A nostalgic story-based game about fangirls on the early 00's internet. Free demo available on Steam. Available for purchase on on Steam and itch.io."
    />
    <meta property="og:url" content="https://playterranova.com/" />
    <meta property="og:site_name" content="Terranova" />
    <meta property="og:image" content={articleImage} />
    <meta property="og:image:secure_url" content={articleImage} />
  </Helmet>
)
